$blue: #00a1ea;
$font-stack: "Roboto", "Helvetica", "Arial", sans-serif;

body {
    font-family: $font-stack;
    margin: 0;
    padding: 0;
}

.link-button {
    font-size: 16px;
    display: inline;
    cursor: pointer;
    color: $blue;
    border: none;
    outline: none;
    background-color: transparent;
}

.nav-title {
    font-weight: 400;
    flex-grow: 1;

    @media only screen and (max-width: 425px) {
        display: none;
    }
}

.nav-img {
    max-width: 150px;
    margin-right: 20px;
    cursor: pointer;

    &.icon {
        display: none;
        max-width: 80px;

        @media only screen and (max-width: 600px) {
            display: block;
        }
    }

    &.logo {
        display: block;

        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
}

.nav-user-url {
    font-family: $font-stack;
    margin-right: 15px;
    color: white;

    a {
        font-weight: bold;
        color: white;

        &:hover {
            color: #e2e2e2;
        }
    }

    @media only screen and (max-width: 720px) {
        display: none;
    }
}

.align-right {
    display: flex;
    align-items: center;
    width: 100%;

    @media only screen and (min-width: 720px) {
        width: unset;
    }

    .MuiIconButton-colorInherit {
        margin-left: auto;

        @media only screen and (min-width: 720px) {
            margin-left: inherit;

            .nav-user-url {
                margin-left: auto;
            }
        }
    }
}

.hero {
    position: relative;

    height: calc(80vh - 160px);
    @media only screen and (max-width: 920px) {
        height: fit-content;
    }

    &.blue {
        background-color: $blue;
    }

    .hero-content {
        display: flex;
        flex-wrap: wrap-reverse;
        height: 100%;
        padding-top: 50px;
        place-content: center;
        place-items: center;

        @media only screen and (max-width: 920px) {
            height: fit-content;
            padding: 30px 0;

        }

        .hero-img {
            width: 27%;
            margin-left: 40px;
            text-align: center;

            img {
                width: 80%;

                @media only screen and (max-width: 920px) {
                    width: 40%;
                }

                @media only screen and (max-width: 768px) {
                    width: 50%;
                }

                @media only screen and (max-width: 425px) {
                    width: 80%;
                }

                @media only screen and (max-width: 320px) {
                    width: 90%;
                }
            }

            @media only screen and (max-width: 920px) {
                width: 100%;
                margin-bottom: 20px;
                margin-left: 0;
            }
        }

        .hero-body {
            font-family: $font-stack;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            color: white;
            place-content: center;
            place-items: flex-start;

            @media only screen and (max-width: 920px) {
                padding-right: 20px;
                padding-left: 20px;
            }

            .hero-info {
                font-size: 1rem;
                display: block;
                width: 100%;
                margin-top: 0;
                margin-bottom: 5px;
                text-align: left;

                @media only screen and (max-width: 920px) {
                    text-align: center;
                }
            }

            .hero-title {
                font-size: 3rem;
                font-weight: 200;
                display: block;
                width: 100%;
                margin: 20px 0;
                text-align: left;

                @media only screen and (max-width: 920px) {
                    text-align: center;
                }

                @media only screen and (max-width: 683px) {
                    font-size: 2rem;
                }

                @media only screen and (max-width: 425px) {
                    font-size: 1.7rem;
                }
            }

            .hero-subtitle {
                font-size: 1.5rem;
                font-weight: 400;
                display: block;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
                text-align: left;

                @media only screen and (max-width: 920px) {
                    text-align: center;
                }

                @media only screen and (max-width: 425px) {
                    font-size: 1.25rem;
                }
            }

            .hero-learn-more {
                align-self: center;
                margin-top: 10px;
                margin-bottom: 15px;
            }

            .hero-cta {
                align-self: center;
            }
        }
    }

    .hero-bottom {
        position: absolute;
        z-index: -1;
        top: calc(100% - 10px);
        width: 100%;

        @media only screen and (max-width: 360px) {
            top: 100%;
        }
    }
}

.page-content {
    max-width: 1024px;
    margin: 250px auto 200px;

    padding: 5px 30px;

    @media only screen and (min-width: 1700px) {
        margin-top: 350px;
    }

    @media only screen and (min-width: 1400px) and (max-width: 1700px) {
        margin-top: 280px;
    }

    @media only screen and (max-width: 1100px) {
        margin-top: 200px;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 130px;
    }

    @media only screen and (max-width: 425px) {
        margin-top: 80px;
    }

    #brought-to-you-by-section {
        margin-bottom: 75px;
        text-align: center;
        color: #a5a5a5;

        h3 {
            font-size: 2rem;
            color: #00a1ea;
        }

        a {
            font-weight: bold;
            text-decoration: none;
            color: inherit;
        }
    }

    .text-section {
        font-family: $font-stack;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .img-container {
            order: 1;
            width: calc(50% - 40px);
            height: auto;
            margin-top: 10px;

            @media only screen and (max-width: 1024px) {
                width: calc(40% - 20px);
            }

            @media only screen and (max-width: 683px) {
                order: 1 !important;
                width: 80%;
            }

            @media only screen and (max-width: 425px) {
                width: 100%;
            }

            * {
                max-width: 100%;
            }

            .icon-credit {
                font-size: 8px;
                text-align: center;
                color: #eeeeee;

                a {
                    color: #eeeeee;
                }
            }
        }

        &.img-right .img-container {
            order: 3;
        }

        @media only screen and (max-width: 683px) {
            text-align: center;
            place-content: center;
        }

        .text-container {
            align-self: flex-start;
            order: 2;
            width: 50%;
            margin-top: 40px;
            margin-right: 40px;

            @media only screen and (max-width: 1024px) {
                width: 40%;
            }

            @media only screen and (max-width: 683px) {
                align-self: unset;
                width: 100%;
                margin-top: 0;
                margin-right: 0;
                margin-left: 0;
            }

            h2 {
                font-weight: 400;
            }

            p {
                font-size: 18px;
                font-weight: 300;
            }
        }

        &:not(.img-right) .text-container {
            margin-right: 0;
            margin-left: 40px;
        }

        &:last-child {
            margin-bottom: 50px;
        }
    }

    hr {
        margin: 30px 0 12px;
        content: "\2022 \2022 \2022";
        text-align: center;
        border: 0;

        &::before {
            font-size: 70px;
            content: "\2022 \2022 \2022";
            color: $blue;
        }
    }
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;

    &.sign-up {
        .user-card {
            margin-bottom: 20px;
        }
    }

    .modal-content {
        font-family: $font-stack;
        padding: 20px;
        border: none;
        border-radius: 0;
        outline: none;
        background-color: white;

        -moz-box-shadow: 6px 10px 18px 0 rgba(0, 0, 0, 0.6);
        -webkit-box-shadow: 6px 10px 18px 0 rgba(0, 0, 0, 0.6);
        box-shadow: 6px 10px 18px 0 rgba(0, 0, 0, 0.6);

        h2 {
            margin-top: 10px;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 15px;
            place-items: center;

            .MuiTextField-root {
                width: 90%;

                &:not(:first-child) {
                    margin-top: 20px;
                }
            }

            .user-type-select {
                display: flex;
                margin-top: 10px;
                place-items: center;

                place-content: center;

                & > label {
                    & > label {
                        line-height: 46px;
                        margin-right: 10px;
                    }
                }
            }

            input[disabled] {
                cursor: not-allowed;
            }
        }

        .long-text-container {
            width: 50vw;
            min-width: 310px;

            &.gmb-auth {
                width: 60vw;
                margin-top: 0;
                margin-bottom: 20px;
                text-align: center;

                p {
                    margin-top: 0;
                }
            }
        }

        .modal-controls {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 15px;

            .forwards {
                margin-left: auto !important;
            }
        }

        .exit-sign-up {
            font-family: $font-stack;
            font-size: 16px;
            display: inline-block;
            margin-left: 10px;
            cursor: pointer;
            text-decoration: underline;
            color: $blue;
            border: none;
            background-color: transparent;
        }
    }
}

.user-card {
    display: flex;
    width: 80%;
    place-items: center;

    .name-container {
        width: 100%;
        margin-left: 20px;

        .company-name {
            line-height: 50px;
            width: 100%;
            min-width: 170px;
            max-width: 350px;
            margin: 0;

            @media only screen and (min-width: 375px) {
                min-width: 230px;
            }
        }

        p.company-name {
            line-height: 25px;
        }

        .username {
            width: fit-content;
            margin-top: 5px;
            margin-bottom: 0;
            margin-left: 0;
            cursor: default;
            -moz-user-select: none;

            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            color: #747474;

            &::before {
                content: "/"
            }
        }
    }
}

.company-info {
    h1 {
        margin-top: 0;
    }
}

.loading-page {
    z-index: 100;
    display: flex;
    width: 100vw;

    height: 100vh;
    background-color: #e7e7e7;
    place-items: center;
    place-content: center;
}

.settings-page {
    display: flex;
    flex-direction: column;
    padding: 10px;
    place-items: center;

    @media only screen and (max-width: 425px) {
        padding: 10px 5px;
        place-items: flex-start;
    }

    & > * {
        width: 550px;
        max-width: 80%;
    }

    .user-info {
        & > *:not(h1) {
            padding-left: 10px;
        }

        .email {
            font-size: 18px;
        }

        input {
            width: 100%;
        }
    }
}

.profile-page {
    form {
        display: contents;
    }

    h1 {
        margin-top: 40px;
    }

    div:first-child > h1 {
        margin-top: 0;
    }

    hr {
        display: block;
        width: 80%;
        min-width: 270px;
        margin-top: 25px;
        margin-bottom: 10px;
        margin-left: 0;
        border: 1px solid rgba(93, 93, 93, 0.2);
    }

    .MuiBox-root {
        @media only screen and (max-width: 375px) {
            padding: 24px 12px;
        }
    }

    .wide-text-input:not(:first-of-type) {
        margin-top: 15px !important;
    }
}

.link-container {
    display: flex;
    flex-direction: column;
    place-content: center;
}

$link-color: #2582e1;
.link {
    position: relative;
    width: calc(100% - 40px);
    margin: 10px 0;
    padding: 10px 20px;
    transition: background-color 200ms ease-out, filter 200ms ease-out;
    border-radius: 15px;
    background-color: rgba(180, 180, 180, 0.39);

    @mixin icon-button {
        font-size: 20px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        color: #ff6a5d;
        border: none;
        outline: none;
        background-color: transparent;
    }

    &.priority {
        background-color: rgba(156, 1, 237, 0.91);

        input, p, textarea {
            color: white;

            &::placeholder {
                transition: color 200ms ease-out;
                color: #c28eff;
            }

            &:focus {
                border-color: rgba(117, 7, 172, 0.73) !important;
            }
        }

        .link-url {
            color: #62a4ff;
        }

        .link-analytics {
            color: rgba(255, 255, 255, 0.4) !important;

            &:hover {
                color: rgba(255, 255, 255, 0.7) !important;
            }
        }
    }

    $gray: rgba(106, 106, 106, 0.5);

    .link-url-container {
        position: relative;

        .link-url {
            font-weight: 500;
            text-decoration: underline;
            color: $link-color;

            &:placeholder-shown {
                text-decoration: none;
            }
        }

        .inline-copy-link {
            @include icon-button;

            font-size: 16px;

            position: absolute;
            top: 0;
            right: 0;
            padding-right: 10px;
            padding-left: 5px;

            color: $gray;
            height: 24px;

            &:hover {
                color: rgba(65, 65, 65, 0.76);
            }

            &[disabled] {
                cursor: not-allowed !important;
                color: rgba(153, 153, 153, 0.5) !important;
            }
        }
    }

    .link-options {
        position: absolute;
        bottom: 23px;
        left: 0;
        width: 100%;
        height: 23px;
        border-top: 1px solid $gray;

        .link-grip {
            position: absolute;
            top: 13px;
            right: 15px;
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
            cursor: ns-resize;
            color: $gray;
        }

        .remove-link, .pin-link, .link-analytics, .copy-link {
            @include icon-button;

            position: absolute;
            top: 12px;
            left: 20px;

            &:hover {
                color: #ec4134;
            }
        }

        .toggle-link {
            position: absolute;
            top: 4px;
            right: 40px;
        }

        .pin-link {
            top: 12px;
            left: 55px;
            height: min-content;
            transition: none !important;

            color: $gray;

            &:hover {
                color: rgba(65, 65, 65, 0.76);
            }

            &.pinned {
                color: #ffd926;

                &:hover {
                    color: #dfcf24;
                }
            }
        }

        .link-analytics, .copy-link {
            left: 95px;
            color: $gray;

            &:hover {
                color: rgba(65, 65, 65, 0.76);
            }
        }

        .copy-link {
            top: 11px;
            right: 105px;
            left: unset;
        }
    }

    input, p, textarea {
        font-family: $font-stack !important;
        display: block;
        width: calc(100% - 16px);
        min-width: calc(100% - 16px);
        max-width: calc(100% - 16px);

        padding: 4px 8px;
        transition: color 200ms ease-out;
        border: none;

        border-radius: 6px;
        background-color: transparent;
    }

    .link-title {
        font-size: 1.25em;
        font-weight: bold;
    }

    .link-description {
        font-family: inherit;
        /* min-height: 32px; */
        font-size: 0.9em;
        max-height: 100px;
        margin-top: 5px;
    }
}

.add-link {
    width: 100px;
    margin: 10px !important;

    @media only screen and (max-width: 425px) {
        width: 100%;
        margin: 10px 0 !important;
    }
}

.no-links-warning {
    h1 {
        margin-top: 0 !important;
    }
}

.error-page {
    font-family: $font-stack;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    padding: 20px;
    background-color: #e7e7e7;

    h1 {
        font-size: 2.5rem;
        font-weight: 500;
    }

    p {
        font-size: 1.25rem;
    }

    a {
        font-weight: bold;
        color: $link-color;

        &:hover {
            color: #00a1ea;
        }
    }
}

.link-page {
    font-family: $font-stack;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: calc(100vh - 100px);
    padding: 50px;

    @media only screen and (max-width: 425px) {
        padding: 50px 15px;
    }

    $mute-black: rgba(0, 0, 0, 0.85);

    .branding-container {
        font-size: 12px;
        font-weight: 400;
        position: fixed;
        bottom: 10px;
        width: 100%;

        .branding-link {
            text-align: center;
        }

        a {
            font-size: 12px;
            font-weight: 400;
            margin: 10px auto;
            text-decoration: none;
            opacity: 0.5;
            color: rgba(237, 237, 237, 0.75);

            &:hover {
                color: #ededed;
            }
        }
    }

    .user-profile {
        display: flex;
        align-items: center;
        flex-direction: column;

        .user-icon-large {
            width: 140px;
            max-width: 380px;
            height: 140px;

            &.avatar-no-cut {
                width: auto;
                border-radius: 0;

                img {
                    width: auto;
                    height: 100%;
                }
            }
        }

        h1 {
            margin-bottom: 0;
            color: $mute-black;

            a {
                color: $link-color;

                &:hover {
                    color: #00a1ea;
                }

                svg {
                    width: 20px;
                    margin-left: 10px;
                }
            }
        }

        .username {
            margin-top: 10px;
            cursor: pointer;
            color: rgba(80, 80, 80, 0.66);

            &:hover {
                color: rgba(46, 46, 46, 0.93);
            }
        }

        .connections-container {
            display: flex;

            a {
                margin: 0 8px;
                color: black;
                outline: none;

                svg {
                    font-size: 30px;
                }
            }
        }
    }

    .user-bio {
        font-weight: 300;
        display: block;
        max-width: 80%;
        margin: 10px auto 20px;
        text-align: center;
    }

    .link-container {
        width: 100%;
        max-width: 800px;
        margin-top: 40px;

        .link {
            overflow-x: hidden;
            width: 60%;
            margin: 15px auto;
            padding-left: 15px !important;
            cursor: pointer;

            &:first-child {
                margin-top: 0;
            }

            @media only screen and (max-width: 500px) {
                width: calc(100% - 60px);
            }

            &:hover {
                background-color: rgba(149, 149, 149, 0.44);
            }

            &.priority:hover {
                background-color: rgb(135, 11, 196);
            }

            &.auto {
                text-align: center;
                border-radius: 0;

                * {
                    width: 100%;
                    margin: 5px auto;
                    padding: 0;
                }

                &:hover {
                    filter: brightness(0.85) !important;
                }
            }

            * {
                display: block;
                width: max-content;
                padding-left: 8px;
            }

            .link-favicon {
                position: absolute;
                top: calc(50% - 35px);
                left: 50px;
                width: 70px;
                margin: 0;

                @media only screen and (max-width: 700px) {
                    left: 25px;
                }

                @media only screen and (max-width: 550px) {
                    top: calc(50% - 25px);
                    left: 15px;
                    width: 50px;
                }

                @media only screen and (max-width: 425px) {
                    display: none;
                }
            }

            .link-title {
                font-family: inherit !important;
                font-size: 20px;
                margin-top: 0;
                margin-bottom: 0;
                transition: color 200ms ease-out;
                text-decoration: none;
                color: #62a4ff;

                &:hover {
                    color: #00a1ea;
                }
            }

            .link-url {
                font-family: inherit !important;
                margin-top: 0;
                transition: color 200ms ease-out;
            }

            .link-description {
                font-family: inherit !important;
                margin-bottom: 5px;
                transition: color 200ms ease-out;
            }
        }
    }
}

.themes-page {
    padding: 20px 30px;

    @media only screen and (max-width: 425px) {
        padding: 20px 10px;
    }

    .theme-menu {
        width: calc(100% - 60px);
        padding: 20px 30px;
        border-radius: 20px;
        background-color: rgba(180, 180, 180, 0.39);

        &:not(:first-child) {
            margin-top: 30px;
        }

        @media only screen and (max-width: 375px) {
            width: calc(100% - 20px);
            padding: 20px 10px;

            h1 {
                text-align: center;
            }
        }

        h1 {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        h2 {
            margin-top: 15px;
            margin-bottom: 0;
            margin-left: 15px;
        }

        .theme-options {
            display: flex;
            flex-wrap: wrap;
            margin-left: 0;
            place-content: center;

            &:not(.links) {
                justify-content: space-around;
            }

            &.fonts {
                width: 70%;
                margin: auto;

                .font {
                    display: flex;
                    flex-direction: column;
                    width: 100px;
                    height: 100px;
                    margin: 10px;
                    cursor: pointer;
                    transition: border-color 200ms ease-out;
                    border: 2px solid black;
                    border-radius: 10px;
                    place-items: center;
                    place-content: center;

                    &.selected {
                        font-weight: bold;
                        border-color: #8a3ab5;
                    }

                    span {
                        display: block;
                        text-align: center;

                        &.font-preview {
                            font-size: 40px;
                            line-height: 45px;
                        }
                    }
                }
            }

            .phone {
                font-weight: bold;
                display: flex;

                align-items: center;
                justify-content: center;
                width: 130px;
                height: 210px;
                margin: 10px 20px;
                cursor: pointer;
                transition: all 150ms ease-out;
                border: 4px solid #0a0a0a;

                border-radius: 20px;

                background-repeat: no-repeat !important;

                background-position: center !important;

                background-size: cover !important;

                &:hover {
                    filter: brightness(0.8);
                }

                &.selected {
                    border: 4px solid #8a3ab5 !important;
                }

                p {
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    width: min-content;
                    height: max-content;
                    text-align: center;
                }
            }

            .slider-picker {
                width: 90%;
            }

            .link-container {
                width: 100%;
                margin: auto;

                .link {
                    width: calc(100% - 60px);
                    margin: 10px auto;
                    padding-left: 20px;

                    div {
                        background-color: rgba(76, 115, 171, 0.66);

                        &.link-title-placeholder {
                            width: 100%;
                            height: 30px;
                            border-radius: 15px;
                        }

                        &.link-url-placeholder {
                            width: 70%;
                            height: 20px;
                            margin-top: 15px;
                            border-radius: 10px;
                        }

                        &.link-description-placeholder {
                            position: relative;
                            width: 100%;
                            height: 20px;
                            margin-top: 25px;
                            margin-bottom: 25px;
                            border-radius: 10px;
                            background-color: rgba(128, 128, 128, 0.49);

                            &::after {
                                position: absolute;
                                top: 25px;
                                display: block;
                                width: 50%;
                                height: 20px;
                                content: "";
                                border-radius: 10px;
                                background-color: rgba(128, 128, 128, 0.49);
                            }
                        }
                    }
                }
            }
        }
    }
}

.background-edit-content {
    .chrome-picker {
        font-family: "Menlo", $font-stack !important;
        margin: 0 auto;
    }
}

.profile-contents {
    position: relative;
    display: flex;

    .edit-profile {
        width: 60%;
    }

    .profile-preview {
        position: sticky;
        top: 48px;
        left: 60%;
        overflow: auto;
        width: calc(40% - 1px);
        height: calc(100vh - 48px);
        border-left: 1px solid #acacac;
        box-shadow: 1px 1px 10px #717171;

        .link-page {
            height: unset;
            min-height: 100%;
            padding: 25px 30px 10px 25px;
            transition: background-color 200ms ease-out;

            @media only screen and (max-width: 810px) {
                padding: 25px 15px 10px 10px;
            }

            .link {
                width: calc(100% - 30px);
            }

            h1 {
                margin-top: 20px;
            }

            .link-favicon {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 650px) {
        .edit-profile {
            width: 100%;
        }

        .profile-preview {
            display: none;
        }
    }
}

.link.editable {
    padding-bottom: 60px;
}

.link-analytics-modal {
    .MuiDialog-paper {
        width: 60%;
        max-width: 900px;

        @media only screen and (max-width: 425px) {
            width: 90%;
            margin: 0;
        }
    }

    .MuiDialogContent-root {
        overflow-x: hidden;

        @media only screen and (max-width: 425px) {
            padding: 8px 12px;
        }
    }

    .success {
        color: #3bae5f;
    }
}

.chart-no-data {
    font-style: italic;
    margin: 32px auto;
    padding: 0 10%;
    color: rgba(0, 0, 0, 0.54);
}

.stats-page {
    h1 {
        margin-top: 10px;
    }

    .loading-container {
        width: 150px;
        margin: 75px auto;
    }

    h2:not(:first-of-type) {
        margin-top: 50px;
    }

    .MuiPaper-elevation4 {
        z-index: 1;
    }
}
