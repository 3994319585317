@import "layout";

.btn-link {
    font-family: $font-stack;
    margin: 0 10px;
    cursor: pointer;
    text-decoration: underline;
    border: none;
    outline: none;
    background-color: transparent;

    &.top {
        font-size: 1rem;
        margin: 15px 0;
    }

    &.white {
        color: white;

        &:hover {
            color: #e2e2e2;
        }
    }
}

.hero-cta {
    margin-top: 15px !important;
}

.file-input {
    position: relative;
    width: 50px;
    height: 50px;

    .large & {
        width: 80px;
        height: 80px;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;

        &[disabled] ~ .file-input-ui {
            cursor: not-allowed !important;
        }
    }

    .file-input-ui {
        position: relative;
        width: 50px;
        height: 50px;
        cursor: pointer;

        transition: all 150ms ease-in;

        border-radius: 50px;

        .large & {
            width: 80px;
            height: 80px;
        }

        &:hover {
            $box-shadow: 0 0 0 6px rgba(68, 68, 68, 0.2);

            -moz-box-shadow: $box-shadow;
            -webkit-box-shadow: $box-shadow;
            box-shadow: $box-shadow;

            .upload-icon {
                opacity: 0.9;
            }
        }

        &.error {
            $box-shadow: 0 0 0 6px rgba(187, 31, 19, 0.5);

            -moz-box-shadow: $box-shadow;
            -webkit-box-shadow: $box-shadow;
            box-shadow: $box-shadow;
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background: #eeeeee;

            .large & {
                width: 80px;
                height: 80px;
                border-radius: 80px;
            }
        }

        .upload-icon {
            position: absolute;
            right: 7px;
            bottom: 7px;
            width: 15px;
            height: 15px;
            transition: opacity 100ms ease-in 50ms;
            opacity: 0.6;
            color: #a8a8a8;

            .large & {
                right: 10px;
                bottom: 10px;
                width: 25px;
                height: 25px;
            }
        }
    }
}

.user-info {
    .wide-text-input .MuiInputBase-root {
        margin-left: -10px;
    }
}

.profile-info {
    .bio {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 14px;
        margin-top: 25px;
        padding: 10px;
        border-radius: 5px;
        outline: none;

        &:focus {
            width: unquote("calc(max(90%, 260px) - 1px)");
            min-width: unquote("calc(max(90%, 260px) - 1px)");
            max-width: unquote("calc(max(90%, 260px) - 1px)");
            border: 2px solid #00a1ea !important;
        }
    }
}

.wide-text-input {
    &.MuiTextField-root {
        width: 80%;
    }

    .MuiInputBase-root {
        width: 100%;
        min-width: 270px;
        max-width: 700px;

        @media only screen and (min-width: 375px) {
            min-width: 330px;
        }
    }
}
