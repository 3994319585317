.ap-nostyle-input-icon {
    display: none;
}

.ap-nostyle-input {
    max-width: calc(100% - 28px);
}

.algolia-places-nostyle {
    z-index: 10;
    width: 100%;
}

.algolia-container {
    width: 80%;
    margin-top: 25px !important;
}

.ap-nostyle-dropdown-menu.ap-nostyle-with-places {
    z-index: 10;
    top: calc(100% - 5px) !important;
    width: calc(100% - 2px);
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;

    .ap-nostyle-dataset-places {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 0 0 5px 5px;
        background-color: white;

        .ap-nostyle-suggestion {
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid rgba(126, 126, 126, 0.14);

            &:hover {
                background-color: rgba(0, 0, 0, 0.09);

                //noinspection CssBrowserCompatibilityForProperties
                .ap-suggestion-icon svg {
                    fill: #c1c1c1;
                }
            }

            em {
                font-weight: bold;
                font-style: normal;
            }
        }
    }
}

.MuiOutlinedInput-root {
    &:hover .ap-nostyle-dataset-places {
        border-color: rgba(0, 0, 0, 0.64);
    }

    &.Mui-focused {
        .ap-nostyle-dropdown-menu.ap-nostyle-with-places {
            width: calc(100% - 4px);
        }

        .ap-nostyle-dataset-places {
            border: 2px solid #00a1ea !important;
        }
    }

    &.Mui-error {
        .ap-nostyle-dataset-places {
            border-color: #f44336 !important;
        }
    }
}
